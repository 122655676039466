import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import Link from "../components/link";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import Navigation from "../components/navigation";
import SVGRing from "../components/SVG/ring";
import SVGVeryThinRing from "../components/SVG/very-thin-ring";
import { checkPropertiesForNull } from "../../utils";
import { SafeHtmlParser } from "../components/safeHtmlParser";
import { v4 as uuidv4 } from "uuid";

const FreeAssessmentPage = () => {
	const data = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					siteUrl
				}
			}
			pageData: wpPage(slug: { eq: "free-assessment" }) {
				freeAssessmentFields {
					freeAssessmentBanner {
						freeAssessmentHeading
					}
					# freeAssessmentLocations {
					# 	freeAssessmentLocationTitle
					# 	freeAssessmentLocationCard {
					# 		freeAssessmentCardTitle
					# 		freeAssessmentCardLink {
					# 			title
					# 			url
					# 			target
					# 		}
					# 		freeAssessmentCardBackgroundImage {
					# 			node {
					# 				altText
					# 				localFile {
					# 					childImageSharp {
					# 						gatsbyImageData(
					# 							formats: [AUTO, WEBP]
					# 							quality: 100
					# 							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
					# 							layout: CONSTRAINED
					# 							placeholder: BLURRED
					# 						)
					# 					}
					# 				}
					# 			}
					# 		}
					# 	}
					# }
					freeAssessmentSimpleContentSection {
						freeAssessmentSimpleContentText
					}
				}
				seoFields {
					metaTitle
					metaDescription
					opengraphTitle
					opengraphDescription
					productSchema
					image {
						node {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
				}
			}
		}
	`);

	const {
		pageData: { seoFields, freeAssessmentFields },
	} = data;

	const {
		freeAssessmentBanner,
		freeAssessmentLocations,
		freeAssessmentSimpleContentSection,
	} = freeAssessmentFields;

	const siteUrl = data.site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Free Assessment",
				item: {
					url: `${siteUrl}/free-assessment`,
					id: `${siteUrl}/free-assessment`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<LocalBusinessSchema />
			<GatsbySeo
				title={seoFields?.metaTitle}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.localFile.publicURL}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: `${seoFields?.image?.node.altText}`,
						},
					],
				}}
			/>

			<section
				className="position-relative"
				style={{
					background:
						"transparent linear-gradient(180deg, #E0F1F9 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
					opacity: 1,
				}}
			>
				<Navigation background="none" />
				<SVGVeryThinRing
					style={{ left: "-17%" }}
					className="position-absolute top--70 d-none d-xl-block "
				/>
				<SVGRing
					style={{ width: "25rem", height: "25rem" }}
					className="position-absolute top--60 end-10 d-none d-xl-block"
				/>
				<Container>
					<Row>
						<Col>
							<h1 className="text-primary mt-8 mb-5 text-center display-4 jost-bold">
								{freeAssessmentBanner?.freeAssessmentHeading ||
									"Free Assessment"}
							</h1>
						</Col>
					</Row>
				</Container>
			</section>

			{freeAssessmentSimpleContentSection &&
				!checkPropertiesForNull(freeAssessmentSimpleContentSection, [
					"freeAssessmentSimpleContentText",
				]) && (
					<section>
						<Container>
							<Row>
								<Col>
									{" "}
									<div className="fs-5 mb-6">
										<SafeHtmlParser
											htmlContent={
												freeAssessmentSimpleContentSection?.freeAssessmentSimpleContentText
											}
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
				)}
			{/* {freeAssessmentLocations &&
        !checkPropertiesForNull(freeAssessmentLocations, [
          "freeAssessmentLocationTitle",
        ]) && (
          <section className="mb-8">
            <Container>
              <Row>
                <Col>
                  <h3 className="text-primary mb-4">
                    {freeAssessmentLocations?.freeAssessmentLocationTitle ||
                      "Locations"}
                  </h3>
                </Col>
              </Row>
              {freeAssessmentLocations.freeAssessmentLocationCard &&
                freeAssessmentLocations.freeAssessmentLocationCard.length >
                  0 && (
                  <Row className="justify-content-center">
                    {freeAssessmentLocations.freeAssessmentLocationCard.map(
                      (item) => (
                        <Col
                          className="text-center mt-4 mt-xl-0"
                          md={6}
                          xl={4}
                          key={uuidv4()}
                        >
                          <Link
                            className="cta-link"
                            to={item?.freeAssessmentCardLink.url}
                          >
                            {" "}
                            <GatsbyImage
                              style={{ width: "100%", height: "280px" }}
                              image={
                                item?.freeAssessmentCardBackgroundImage.node
                                  ?.localFile.childImageSharp.gatsbyImageData
                              }
                              alt={
                                item?.freeAssessmentCardBackgroundImage.node
                                  ?.altText
                              }
                            />
                            <p className="fs-4 text-primary mt-3 mb-0">
                              {item?.freeAssessmentCardTitle}
                            </p>
                            <p className="fs-4 jost-bold contact-link">
                              {item?.freeAssessmentCardLink.title}
                            </p>
                          </Link>
                        </Col>
                      )
                    )}
                  </Row>
                )}
            </Container>
          </section>
        )} */}
		</Layout>
	);
};

export default FreeAssessmentPage;
